import React, { useContext, useState } from 'react'
import Upload from '../component/upload/upload'
import Form from '../component/form/form'
import { doPOST } from '../utils/HttpUtils'
import Result from '../component/Result'
import { AppContext } from '../services/context/AppContext'
const MainPage = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
        // '00020669-0359-4d8c-b6cd-b3d1f2986023.jpg',
    ])


    const { userData } = useContext(AppContext)




    const handleSubmit = async (file) => {
        try {
            setLoading(true)
            // e.preventDefault();app

            const formData = new FormData();
            formData.append("file", file);
            formData.append("client_name", userData)
            // const response = await doPOST("http://65.1.106.158:9090/api/v1/reversesearch/searchImage", formData)
            const response = await doPOST("/api/v1/reversesearch/searchImage", formData)
            console.log(response)
            if (response?.images) {
                setData(response.images)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    };

    return (
        <div className='d-md-flex flex-row justify-content-center flex-1 m-2  h-100 w-100 px-2'>
            <div className='upload-wrapper border-end border-1 border_style me-3' style={{ height: "85vh" }}>
                <Upload handleSubmit={handleSubmit} />
            </div>
            <div style={{ flex: 1, position: 'relative' }} className='' >
                <div className='result-text mb-4 poppins-medium ps-3'>Results</div>
                {
                    loading ? <div style={{ inset: 0 }} className='d-flex flex-column justify-content-center align-items-center mt-5 position-absolute'>
                        <div className='loader'></div>
                        <div className='popplin-bold'>Working our magic...</div>
                    </div> :
                        <>

                            <Result data={data} />
                        </>
                }


            </div>

        </div>
    )
}

export default MainPage