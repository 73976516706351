import React, { useEffect, useState, useRef } from 'react';
import './uploader.css';
import { doPOST } from '../../utils/HttpUtils';

const Upload = ({ handleSubmit }) => {
    const [fileData, setFileData] = useState(null);
    const fileInputRef = useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFileData(selectedFile);
            handleSubmit(selectedFile);
        }
        // Clearing the input value to allow re-uploading the same file
        e.target.value = null;
    };

    return (
        <div style={{ /* height: '85vh',  */maxWidth: "fit-content", marginTop: 90 }} className='d-flex flex-column justify-content-center pe-4 me-2 '>
            <form className='upload-form' action='' onClick={() => fileInputRef.current.click()}>
                <input
                    type='file'
                    accept=".jpg,.jpeg,.png"
                    id={"file"}
                    className='input-field'
                    hidden
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
                {fileData ? (
                    <img
                        src={URL.createObjectURL(fileData)}
                        alt={"file"}
                        style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                    />
                ) : (
                    <img
                        style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                        src={require('../../assets/upload-2.png')}
                    />
                )}
            </form>
            <div className='upload-btn rounded-2 mt-3 w-100 cursor-pointer' onClick={handleClick}>
                <p className='text-white text-center upload-txt p-0 m-0'>
                    {fileData ? 'Upload another image' : 'Upload image'}
                </p>
            </div>
        </div>
    );
};

export default Upload;
