
export const API_METHODS = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELTE',
    PUT: 'PUT'
}

export const ENDPOINTS = {
    login: '/auth/login',
    register: '/auth/signup',
    profile: '/auth/profile',
    upload: '/generate-content'
}