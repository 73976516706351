import React, { useState, useContext } from 'react';

import MainPage from './pages/MainPage';
import Login from './pages/Login';
import { AppContext } from './services/context/AppContext';

function App() {
  const { isLoggedIn, setIsLoggedIn } = useContext(AppContext)

  return (
    <div className=' vw-100 d-flex flex-column justify-content-center align-items-center'>
      <div className='top-bar text-left w-100 poppins-semibold navbar_shadow px-4'>
        Design Chasing
      </div>
      <div className='p-2 w-100 overflow-hidden'>
        {isLoggedIn ? <MainPage /> : <Login />}
      </div>
    </div>
  );
}

export default App;
