import React from 'react';
import { Card } from 'reactstrap';

const Form = ({ className, data, setData, }) => {


    const handleChange = (e) => {
        console.log(e.target.name, e.target.value)
        setData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = (e) => {


    }

    return (
        <Card className={className} onSubmit={handleSubmit}>
            <div className="mb-3 d-flex justify-content-center align-items-center w-100">
                <div className="mb-3 w-100 me-1">
                    <label className="form-label">Applicant's Name</label>
                    <input type="text" name='applicantName' className="form-control" value={data?.applicantName}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3 w-100 ms-1">
                    <label className="form-label">Applicant's Address</label>
                    <input type="text" name='applicantAddress' className="form-control" value={data?.applicantAddress}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="mb-3 d-flex justify-content-center align-items-center w-100">
                <div className="mb-3 w-100 me-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">Type of Equipment</label>
                    <select name="equipmentType" class="form-select" aria-label="Default select example" onChange={handleChange}>
                        <option value="Individual Ex Electrical Apparatus">Individual Ex Electrical Apparatus</option>
                        <option value="Assembly of components">	Assembly of components</option>
                        <option value="Fuel Dispensers">Fuel Dispensers</option>
                    </select>
                </div>
                <div className="mb-3 w-100 ms-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">Country of Origin</label>
                    <select name="countryOfOrigin" class="form-select" aria-label="Default select example" onChange={handleChange}>
                        <option value="India">India</option>
                        <option value="Imported in India">Imported in India</option>
                    </select>
                </div>
            </div>


            <div className="mb-3 d-flex justify-content-center align-items-center w-100">
                <div className="mb-3 w-100 me-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">Manufacturing Address</label>
                    <input type="text" name='manufacturingAddress' className="form-control" value={data?.manufacturingAddress}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3 w-100 ms-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">Name of Testing Laboratory</label>
                    <input type="text" name='laboratoryName' className="form-control" value={data?.laboratoryName}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="mb-3 d-flex justify-content-center align-items-center w-100">
                <div className="mb-3 w-100 me-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">Certificate Number</label>
                    <input type="text" name='certificateNumber' className="form-control" value={data?.certificateNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3 w-100 ms-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">Certificate Date</label>
                    <input type="text" name='certificateDate' className="form-control" value={data?.certificateDate}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3 w-100 ms-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">Certificate Being Attached</label>
                    <select name="certificateBeingAttached" class="form-select" aria-label="Default select example" onChange={handleChange}>
                        <option value="IECEx Certificate of Conformity">IECEx Certificate of Conformity</option>
                        <option value="IECEx Certificate of Conformity">Test report issued by laboratory recognised by the Chief Controller of Explosives</option>
                    </select>
                </div>
            </div>


            <button type="submit" className="btn btn-primary">Submit</button>
        </Card>
    );
};

export default Form;
