import React, { useContext } from 'react'
import { AppContext } from '../../services/context/AppContext'

// const baseURL = 'https://jolex.s3.ap-south-1.amazonaws.com/inventory/'
function Result({ data = [] }) {

    const { baseUrl } = useContext(AppContext)

    return (
        <>
            <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap', marginTop: "40px", overflowY: "scroll", maxHeight: "75vh" }} className=' flex-1 h-100 justify-content-start result-tab'>
                {
                    data?.map((img, index) => (
                        <div key={index} className='image-wrapper mb-4  m-3' /* style={{ height: 200, width: 200, borderRadius: 10, margin: 10 }} */>
                            <img style={{ height: '90%', width: '100%' }} src={`${img}`} className=' mb-3 rounded-3' />
                            <p style={{ width: '90%' }} className='text-center '>Product #{index + 1}</p>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default Result
